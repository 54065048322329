import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

export default function Chat() {
  const [cs, setCs] = useState("");
  const [msg, setMsg] = useState([]);
  const [isShow, setIsShow] = useState(true);
  let { layanan } = useParams();
  const MySwal = withReactContent(Swal);
  const cookies = new Cookies();

  if (layanan === undefined) {
    MySwal.fire({
      title: <strong>Terjadi kesalahan</strong>,
      text: "Mohon akses halaman ini dari menu",
      icon: "error",
    }).then((is) => {
      if (is.isConfirmed) {
        window.history.back();
      }
    });
  } else {
    if (layanan === "konsultasi") {
      layanan = "Konsultasi";
    }

    if (layanan === "pgb") {
      layanan = "Penggunaan Gedung dan Bangunan";
    }

    if (layanan === "pbdi") {
      layanan = "Produk Budidaya Ikan";
    }

    if (layanan === "pbdi") {
      layanan = "Pelatihan Budidaya";
    }

    if (layanan === "pka") {
      layanan = "Pengujian Kualitas Air";
    }

    if (layanan === "anp") {
      layanan = "Analisis Nutrisi Pakan";
    }

    if (layanan === "pkab") {
      layanan = "Penyediaan Kultur Acuan Bakteri";
    }
  }

  const fetchChat = async () => {
    if (cookies.get("chat-id_room") !== undefined) {
      await axios({
        method: "POST",
        url: "https://admin.bpbatmandiangin.com/api/checkchat",
        data: {
          id_room: cookies.get("chat-id_room"),
        },
      }).then((data) => {
        let parsed = data.data;
        if (parsed.msg === "nf") {
          cookies.remove("chat-id_room", { path: "/" });
          cookies.remove("chat-jenis_layanan", { path: "/" });
          setIsShow(true);
        } else if (parsed.msg === "new") {
          setMsg((prev) => [...prev, { is_admin: 1, message: "Selamat datang di SiPasti. Admin kami akan membalas pesan anda dalam beberapa saat" }]);
          setMsg((prev) => [...prev, { is_admin: 1, message: "Untuk melihat list harga silahkan buka link berikut melalui browser anda: https://drive.google.com/file/d/1o77HNR1AC5dk6o6y8nr3oZjh5Y1tZF_E/view?usp=drivesdk" }]);

          parsed.data.forEach((seg) => {
            setMsg((prev) => [...prev, { is_admin: seg.is_admin === 1 ? 1 : 0, message: seg.content }]);
          });
        } else if (parsed.msg === "ongoing") {
          setMsg((prev) => [...prev, { is_admin: 1, message: "Selamat datang di SiPasti. Admin kami akan membalas pesan anda dalam beberapa saat" }]);
          setMsg((prev) => [...prev, { is_admin: 1, message: "Untuk melihat list harga silahkan buka link berikut melalui browser anda: https://drive.google.com/file/d/1o77HNR1AC5dk6o6y8nr3oZjh5Y1tZF_E/view?usp=drivesdk" }]);

          if (parsed.cs_name !== undefined) {
            setCs(parsed.cs_name);
          }

          parsed.data.forEach((seg) => {
            setMsg((prev) => [...prev, { is_admin: seg.is_admin === "1" ? 1 : 0, message: seg.content }]);
          });
        } else if (parsed.msg === "closed") {
          cookies.remove("chat-id_room", { path: "/" });
          cookies.remove("chat-jenis_layanan", { path: "/" });
          setIsShow(true);
        }

        document.getElementById("target").scrollTo(0, document.getElementById("form").scrollHeight);
      });
    }
  };

  useEffect(() => {
    if (cookies.get("chat-jenis_layanan") !== undefined) {
      setIsShow(false);
    }

    return () => {
      fetchChat();
    };
  }, []);

  // khusus pusher js dan setup untu send message
  useEffect(() => {
    const pusher = new Pusher("f6be2502950e843a05d3", {
      cluster: "ap1",
      encrypted: true,
    });

    const channel = pusher.subscribe("bpbat");

    channel.bind("chat", (data) => {
      let parsed = data.data;

      if (parsed.id_room === cookies.get("chat-id_room")) {
        setMsg((prev) => [...prev, { message: parsed.message, is_admin: parsed.is_admin }]);

        if (parsed.cs_name !== undefined) {
          setCs(parsed.cs_name);
        }

        if (parsed.msg !== undefined && parsed.msg === "closed") {
          MySwal.fire({
            title: <strong>LiveChat diakhiri oleh Customer Support</strong>,
            text: "Terima kasih telah menghubungi kami!",
            icon: "success",
          }).then((is) => {
            if (is.isConfirmed) {
              window.history.back();
            }
          });

          cookies.remove("chat-id_room", { path: "/" });
          cookies.remove("chat-jenis_layanan", { path: "/" });
          setIsShow(true);
        }
      }
    });

    return () => {
      pusher.unsubscribe("bpbat");
      document.getElementById("target").scrollTo(0, document.getElementById("target").scrollHeight + document.getElementById("form").scrollHeight);
    };
  }, [msg]);

  // kirim pesan
  async function sendMessage(event) {
    event.preventDefault();
    if (document.getElementsByName("send")[0].value !== "") {
      let send = {
        id_room: cookies.get("chat-id_room"),
        pesan: document.getElementsByName("send")[0].value,
      };

      await axios.post(document.getElementById("form").getAttribute("action"), send).then((data) => {
        document.getElementsByName("send")[0].value = "";
      });
    }
  }

  // generate UUID untuk idroom
  function uuid(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  // handle form data diri
  async function startChat(event) {
    event.preventDefault();
    const id_room = uuid(11);
    const form = document.getElementById("form-chat-start");
    const formData = new FormData(form);
    formData.append("jenis_layanan", layanan);
    formData.append("id_room", id_room);

    if (cookies.get("chat-jenis_layanan") !== undefined || cookies.get("chat-jenis_layanan") !== null || cookies.get("chat-jenis_layanan") !== "") {
      if (cookies.get("chat-jenis_layanan") !== layanan) {
        cookies.set("chat-jenis_layanan", layanan, { path: "/" });
        cookies.set("chat-id_room", id_room, { path: "/" });
      }
    } else {
      cookies.set("chat-jenis_layanan", layanan, { path: "/" });
      cookies.set("chat-id_room", id_room, { path: "/" });
    }

    setMsg((prev) => [...prev, { is_admin: 1, message: "Selamat datang di SiPasti. Admin kami akan membalas pesan anda dalam beberapa saat" }]);
    setMsg((prev) => [...prev, { is_admin: 1, message: "Untuk melihat list harga silahkan buka link berikut melalui browser anda: https://drive.google.com/file/d/1o77HNR1AC5dk6o6y8nr3oZjh5Y1tZF_E/view?usp=drivesdk" }]);

    await axios.post(document.getElementById("form-chat-start").getAttribute("action"), formData);
    await axios
      .post("https://admin.bpbatmandiangin.com/api/sendmessage", {
        id_room: cookies.get("chat-id_room"),
        pesan: document.getElementById("pesan").value,
      })
      .then(() => {
        setIsShow(false);
      });
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-800">
      <div className="flex flex-1 overflow-hidden h-screen max-w-screen-2xl m-auto">
        <div className="p-4 md:p-12 lg:p-20 w-full">
          <div className="max-h-full h-full flex flex-row relative">
            {isShow ? (
              <div className="bg-gray-200 rounded-lg absolute top-0 z-50 w-full h-full flex justify-center items-center">
                <form id="form-chat-start" method="POST" action="https://admin.bpbatmandiangin.com/api/start-chat" class="w-96 p-4 md:p-0">
                  <h1 class="font-bold text-2xl text-slate-600">Isi data diri anda</h1>
                  <p>Petugas kami akan membalas pesan dalam beberapa menit</p>

                  <div class="mt-5">
                    <label for="nama" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      Nama lengkap
                    </label>
                    <input type="text" name="nama" id="nama" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 dark:focus:border-blue-500 block w-full p-2.5 " placeholder="John Doe" required />
                  </div>
                  <div class="mt-2">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      Email
                    </label>
                    <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 dark:focus:border-blue-500 block w-full p-2.5 " placeholder="name@gmail.com" required />
                  </div>
                  <div class="mt-2">
                    <label for="nohp" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      Nomor Handphone
                    </label>
                    <input type="number" name="nohp" id="nohp" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 dark:focus:border-blue-500 block w-full p-2.5 " placeholder="62821xxx" required />
                  </div>

                  <div class="mt-2">
                    <label for="nohp" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      Pesan
                    </label>
                    <textarea type="text" name="pesan" id="pesan" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 dark:focus:border-blue-500 block w-full p-2.5 resize-none" rows={3} placeholder="Halo!" required></textarea>
                  </div>

                  <button onClick={startChat} type="submit" class="text-white items-end bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5">
                    Submit
                  </button>
                </form>
              </div>
            ) : null}
            <form id="form" method="POST" action="https://admin.bpbatmandiangin.com/api/sendmessage" className="relative max-h-full h-full bg-white rounded-lg w-full flex flex-col dark:bg-gray-900 lg:flex-hidden">
              <div className="p-5 border-b font-bold flex justify-between items-center">
                <div>Customer Support: {cs !== "" ? cs : "[Belum ada respon]"}</div>
                <a class="text-blue-500 border-b border-blue-500" href="https://sipasti.bpbatmandiangin.com/document" target="_blank">
                  Lihat list harga klik disini
                </a>
              </div>
              <div className="flex-1 overflow-y-scroll p-5 scrollbar-thumb-color dark:scrollbar-thumb-color-dark scrollbar-width space-y-5" id="target">
                {msg.map((chat, index) => (
                  <div key={index}>
                    {chat.is_admin === 1 ? (
                      <div className="flex justify-start">
                        <div className="w-14 mr-5">
                          <img className="rounded-full w-full" src="https://blalockcompanies.com/commercial/wp-content/uploads/sites/2/2017/08/person.jpg" />
                        </div>
                        <div className="flex-flex-col space-y-5 text-left">
                          <div>
                            <span className="bg-gray-100 text-gray-900 p-5 text-base rounded-r-lg rounded-b-lg inline-flex max-w-xl dark:text-white dark:bg-gray-800 break-words">{chat.message}</span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {chat.is_admin === 0 ? (
                      <div className="flex justify-end flex-wrap">
                        <div className="space-y-5 text-right">
                          <div className="bg-indigo-800 text-white p-5 text-base rounded-l-lg rounded-b-lg inline-block max-w-xl break-words">{chat.message}</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="flex-none p-5">
                <div>
                  <div className="relative flex">
                    <input type="text" placeholder="Ketik pesan" className="w-full focus:outline-none focus:placeholderbg-gray-400 px-5 bg-gray-100 dark:bg-gray-800 rounded-full py-3" name="send" />
                    <div className="ml-5">
                      <button onClick={sendMessage} className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-white bg-indigo-800 hover:bg-indigo-600 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1em" viewBox="0 0 512 512">
                          <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
