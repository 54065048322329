
import React from 'react'
import Sproduk from './Sproduk'

function Produk() {
  return (
    <div>
      <Sproduk/>
      
    </div>
  )
}

export default Produk
