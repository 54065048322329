
import React, { Fragment, useState } from 'react'
import Chat from '../components/Chat1'
import Modal from '../components/Modal'
import Sjasa from './Sjasa'

function Jasa() {
  return (
  
      <div>
        <Sjasa />
      </div>
    
  )
}

export default Jasa
