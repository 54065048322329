import React, { Fragment, useState } from "react";
import Chat from "../components/Chat1";
import Modal from "../components/Modal";
import Slayanan from "./Slayanan";

function Layanan() {
  return (
    <div>
      <Slayanan />
    </div>
  );
}

export default Layanan;
